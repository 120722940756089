import { MentionsRequestParams } from "./../store/ducks/mentions/types";
import { LoginResponse, User } from "@/store/ducks/user/types";
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from "axios";

const API =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_PROD_URL
    : process.env.REACT_APP_API_DEV_URL;

interface ConfigHeaderType extends AxiosRequestHeaders {
  "Content-Type": string;
  Accept: string;
}

const configHeaders: ConfigHeaderType = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const api = axios.create({
  headers: configHeaders,
  baseURL: API,
});

export const login = async (data: User): Promise<AxiosResponse> => {
  const response = await api.post<LoginResponse>("/api/login", data);
  return response;
};

export const mentions = async (
  token: string,
  requesParams: MentionsRequestParams
) => {
  const response = await api.get("/api/v1/mentions", {
    params: requesParams,
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

export const categorys = async (token: string) => {
  const response = await api.get("/api/v1/categories", {
    params: { per_page: 100, sort_field: "order" },
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};

export const retrievePasswordEmail = async (email: string) => {
  const response = await api.post("api/password/email", { email });

  return response.data;
};
export interface IRegister {
  name: string;
  password: string;
  email: string;
  entity?: string;
}

export const register = async (body: IRegister) => {
  const response = await api.post("api/register", body);

  return response.data;
};

export interface IReset {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export const resetPassword = async (body: IReset) => {
  const response = await api.post("api/password/reset", body);
  return response;
};

export interface IChangePassword {
  _id: number;
  email: string;
  password_atual: string;
  password: string;
  password_confirmation: string;
}

export const changePassword = async (body: IChangePassword, token: string) => {
  const response = await api.put(`/api/v1/password/change`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

export const entities = async () => {
  const response = await api.get("/api/v1/entities/?per_page=1000&page=1");

  return response;
};

export const addFavorite = async (id: string, token: string) => {
  try {
    const response = await api.put(
      `/api/v1/mentions/favorite/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response;
  } catch (error) {
    const err = error as AxiosError;
    return err.response;
  }
};

export const deleteFavorite = async (id: string, token: string) => {
  const response = await api.delete(`/api/v1/mentions/favorite/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

export const contact = async (body: String) => {
  const response = await api.post("api/contact", body);

  return response.data;
};