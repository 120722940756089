import { useEffect, useCallback, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import Masonry from "react-masonry-css";

import { Card } from "@/components/Card";

import { Mention } from "@/store/ducks/mentions/types";
import { FilterContext } from "@/contexts/FilterContext";

import { Container, FeedContent, FeedbackMessage } from "./styles";
import { ReactComponent as Spinner } from "@/assets/images/icons/spinner.svg";
import { State } from "@/store";
interface FeedProps {
  mentions: Mention[];
  isLoading: boolean;
}

export const Feed = ({ mentions, isLoading = false }: FeedProps) => {
  const feedref = useRef<HTMLDivElement>(null);
  const { filters, setFilters } = useContext(FilterContext);
  const { loading, last_page } = useSelector((state: State) => state.mentions);

  useEffect(() => {
    const feed = feedref.current;
    function handleScrool(event: Event) {
      if (feed) {
        const scrollHeightMinusScrollTop =
          feed.scrollHeight - feed.scrollTop - 1;
        const feedHeight = feed.offsetHeight;
        if (scrollHeightMinusScrollTop - feedHeight < 1) {
          if (filters.page < last_page) {
            setFilters({
              ...filters,
              page: filters.page + 1,
            });
          }
        }
      }
    }
    feed?.addEventListener("scroll", handleScrool);
    return () => {
      feed?.removeEventListener("scroll", handleScrool);
    };
  }, [filters, setFilters, loading, last_page]);

  const listSize = useCallback(() => {
    return mentions.length > 0;
  }, [mentions.length]);

  return (
    <Container>
      {!isLoading && mentions.length === 0 ? (
        <FeedbackMessage>Nada encontrado</FeedbackMessage>
      ) : null}
      <FeedContent ref={feedref} id="feed-id" className="feed-content">
        <Masonry
          breakpointCols={{ default: 4, 1580: 3, 1280: 2, 640: 1 }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
          style={{
            maxWidth: "100%",
          }}
        >
          {mentions.map((mention) => (
            <Card key={mention.postid} mention={mention} />
          ))}
        </Masonry>

        <div id="observer-target">
          {isLoading && (
            <Spinner
              style={{
                width: 70,
                height: 70,
                margin: "0 auto",
                display: "block",
                position: listSize() ? "relative" : "absolute",
                left: listSize() ? "auto" : "50%",
                top: listSize() ? "auto" : "50%",
                transform: listSize() ? "unset" : "tr()anslate(-50%, -100%)",
              }}
            />
          )}
        </div>
      </FeedContent>
    </Container>
  );
};
