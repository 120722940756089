import { AxiosError, AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { login } from "@/helper/api";

import { LoginResponse } from "./types";
import {
  actionLoginFail,
  actionLoginRequest,
  actionLoginSuccess,
} from "./actions";

export function* sagaLogin({
  payload,
}: ReturnType<typeof actionLoginRequest>): Generator {
  try {
    const response = yield call(login, payload);

    const { data } = response as AxiosResponse;

    yield put(actionLoginSuccess(data as LoginResponse));
  } catch (error) {
    const err = error as AxiosError;
    const message = err.response?.data.message
      ? err.response?.data.message
      : "Erro ao realizar login!";
    yield put(actionLoginFail(message));
  }
}
