import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { actionLogout } from "@/store/ducks/user/actions";
import { FilterContext } from "@/contexts/FilterContext";

import {
  Container,
  SearchContainer,
  ContainerUserAction,
  MobileButton,
  ProfileButton,
} from "./styles";

import { OrderButtons, OrderItem } from "@/components/OrderButtons";

import menuIcon from "@/assets/images/icons/menu_icon.svg";
interface HeaderProps {
  onMenuMobileClick: () => void;
  onHandleSearch: (value: string) => void;
}

export const Header = ({ onMenuMobileClick }: HeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { filters, setFilters } = useContext(FilterContext);

  const handleLogout = () => dispatch(actionLogout());

  function handleOrderButtonsToFilter(data: OrderItem) {
    const filtersAux = { ...filters };

    setFilters({
      ...filtersAux,
      page: 1,
      sort_field: data.value,
      sort_order: data.order,
    });
  }

  return (
    <Container className="header">
      <MobileButton onClick={onMenuMobileClick}>
        <img src={menuIcon} alt="Menu mobile" />
      </MobileButton>
      <SearchContainer>
        <OrderButtons onOrder={(data) => handleOrderButtonsToFilter(data)} />
      </SearchContainer>
      <ContainerUserAction>
        <ProfileButton onClick={() => navigate("/profile")} title="Perfil">
          <FontAwesomeIcon icon={faUser} color="#fff" />
        </ProfileButton>
        <ProfileButton title="Sair" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} color="#fff" />
        </ProfileButton>
      </ContainerUserAction>
    </Container>
  );
};
