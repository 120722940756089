import imageHomeScreen from "@/assets/images/old/home-screen.png";

export const Features = () => {
  return (
      <section className="screen-section has-bg features">
      <div className="wrapper ">
          <div className="col-md-4">
              <p><b>Coletamos e armazenamos</b> os conteúdos publicados pelas instituições públicas.</p>
              <p>Essas publicações podem <br />ser filtradas por <b>data, mídia social, palavra-chave e tipo de instituição.</b></p>
              <p>Assim, sua instituição pode se <b>inspirar com boas ideias e analisar o que está funcionando.</b></p>
          </div>
          <div className="col-md-8">
              <img src={imageHomeScreen} alt="" className="home-screen" />
          </div>
      </div>
  </section>
  );
};
