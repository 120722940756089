import styled from "styled-components";
import { Link } from "react-router-dom";
import { lighten } from "polished";

import { ReactComponent as Spinner } from "@/assets/images/icons/spinner.svg";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Museo;
  > img {
    max-width: 85%;
    display: block;
    margin-bottom: 3rem;
  }
`;

export const Form = styled.form`
  display: flex;
  margin-top: 30px;
  width: 100%;
  flex-wrap: wrap;
  .MuiTextField-root {
    margin-bottom: 15px;
  }
`;

export const FormRow = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  .MuiTextField-root {
    width: 100%;
    margin-bottom: 0;
  }
  p {
    font-size: 10px;
    color: ${(props) => lighten(0.4, props.theme.colors.onBackground)};
  }
`;

export const ActionButton = styled.button`
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.onBackground};
  color: ${(props) => lighten(0.4, props.theme.colors.onBackground)};
  border-radius: 6px;
  font-size: 1.1rem;
  margin-top: 30px;
  padding: 10px;
  height: 45px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  &:hover {
    background: ${(props) => lighten(0.4, props.theme.colors.onBackground)};
  }
`;

export const LinkButton = styled(Link)`
  background: transparent;
  border: unset;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => lighten(0.4, props.theme.colors.onBackground)};
  font-size: 1.1rem;
  display: block;
  width: 100%;
  text-aglin: right;
`;



export const ActionLinks = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  display: block;
`;

export const Loading = styled(Spinner)`
  width: 30px;
  height: 30px;
  margin: "0 auto";
  display: "block";
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
`;


export const TopJaba = styled.div`
  /* Style for "Ferramenta" */
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 1em;
  opacity: 80%;

  h1 {
    font-size 2em;
  }

  b {
    
    color: #7bc4ed;
    font-style: normal;
    line-height: normal;
  }
  /* Text style for "que , auxi" */
  font-style: normal;
  line-height: normal;                  
`;

