import imageLogo from "@/assets/images/old/logo.png";

// import { useState } from "react";

// import { useDispatch } from "react-redux";

// import { actionLoginRequest } from "@/store/ducks/user/actions";

import { LinkButton, TopJaba } from "./styles";

export const SiteHeader = () => {
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
  
    // const dispatch = useDispatch();
    // const { error, loading, errorMessage } = useSelector(
    //   ({ user }: State) => user
    // );
  
    // function handleSubmit() {
    //   dispatch(
    //     actionLoginRequest({
    //       email: email,
    //       password: password,
    //     })
    //   );
    // }
  
    // useEffect(() => {
    //   if (error) {
    //     toast.error(errorMessage);
    //     dispatch(actionLogout());
    //   }
    // }, [error, dispatch, errorMessage]);

    

  return (
    <section className="top-section has-bg">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" 
    integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4" 
    crossOrigin="anonymous">

    </link>    
    <div className="container">
        <div className="row d-flex align-items-center">
            <div className="col-md-6 logo-container">
                <img className="logo" src={imageLogo} alt="Social Media Gov" />                    
            </div>
            <div className="col-md-6 pull-text-right align-items-right">
            {/* <Form> */}
                {/* <TextField
                id="standard-basic"
                label="E-mail"
                variant="standard"
                type="text"
                value={email}
                onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
                onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                id="standard-basic"
                label="password"
                variant="standard"
                type="password"
                value={password}
                onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
                /> */}

                {/* <ActionButton type="button" onClick={handleSubmit}> */}
                {/* Entrar */}
                {/* </ActionButton> */}
                {/* <ActionLinks> */}
                    <LinkButton to="/login"> Acessar o sistema </LinkButton>
                {/* </ActionLinks>                   */}
            {/* </Form> */}
            </div>
        </div>

        <div className="row" style={{marginTop: 40}}>
            <div className="top-jaba col-md-4 d-flex align-items-center">
            <TopJaba>
                <h1>
                    <b>Ferramenta de inteligência</b> {' '}
                    que auxilia instituições públicas a criar, analisar e planejar seus 
                    conteúdos para as redes sociais.
                </h1>
            </TopJaba>                
            </div>
            <div className="col-md-8">
                <div className="video">
                    {/* <iframe 
                        title="videos"
                        src="https://player.vimeo.com/video/340441274" 
                        width="640" 
                        height="360" 
                        frameBorder="0"
                        allow="autoplay; fullscreen" 
                        allowFullScreen></iframe> */}
                        <iframe 
                            width="560" 
                            height="315" 
                            src="https://www.youtube.com/embed/35KmYfFE18A" 
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                        </iframe>
                </div>

            </div>

        </div>
    </div>
    
</section>
  );
};
