import { GroupsBySphere } from "@/store/ducks/categorys/types";
import { action } from "typesafe-actions";
import { ActionTypesCategory } from "./types";

export const actionGetCategorys = () =>
  action(ActionTypesCategory.GET_GATEGORYS_REQUEST);

export const actionGetCategorysSuccess = (payload: GroupsBySphere) =>
  action(ActionTypesCategory.GET_GATEGORYS_SUCCESS, payload);

export const actionGetCategorysFail = () =>
  action(ActionTypesCategory.GET_GATEGORYS_FAIL);
