import { useFormik } from 'formik';
import { api } from "@/helper/api";
import * as Yup from 'yup';
import { toast } from "react-toastify";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const Contact = () => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      phone: '',
      email: '',
      message: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Obrigatório.'),
      phone: Yup.string()
        .min(9, 'Insira um número válido')
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Obrigatório.'),
      email: Yup.string().email('Invalid email address').required('Obrigatório.'),
      message: Yup.string().min(9, 'Insira um número válido')
        .required('Obrigatório.')
    }),
    onSubmit: values => {
      toast.success("Aguarde enquanto enviamos seu contato!");
      console.log('A form was submitted: ');
      let body = JSON.stringify(values)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = api.post("api/contact", body)
        .then(res => {
            toast.success("Recebemos seu contato e em breve retornaremos!");

        })
        .catch(err => {
          toast.error("Não foi possível enviar seu contato! Tente novamente mais tarde.");
          console.error(err)
        })
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div
        className={`float-container`}
      >
        <label htmlFor="firstName">Nome </label>
        <input
          id="firstName"
          type="text"
          {...formik.getFieldProps('firstName')}
          onClick={e => {
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
          onChange={e => {
            formik.handleChange(e);
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
        />
      </div>
      <div className='errorFieds'>
        {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
      </div>

      <div
        className="float-container">
        <label htmlFor="phone">Telefone </label>
        <input
          id="phone"
          type="text"
          {...formik.getFieldProps('phone')}
          onClick={e => {
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
          onChange={e => {
            formik.handleChange(e);
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
        />
      </div>
      <div className='errorFieds'>
        {formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
      </div>

      <div
        className="float-container">
        <label htmlFor="email">E-mail </label>
        <input
          id="email"
          type="email"
          {...formik.getFieldProps('email')}
          onClick={e => {
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
          onChange={e => {
            formik.handleChange(e);
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
        />
      </div>
      <div className='errorFieds'>
        {formik.touched.email && formik.errors.email ? formik.errors.email : null}
      </div>

      <div
        className="float-container">
        <label htmlFor="message">Mensagem </label>
        <textarea
          id="message"
          {...formik.getFieldProps('message')}
          onClick={e => {
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
          onChange={e => {
            formik.handleChange(e);
            var parent = e.currentTarget.parentElement;
            if (parent)
              parent.className = 'float-container active'
          }}
        />
      </div>
      <div className='errorFieds'>
        {formik.touched.message && formik.errors.message ? formik.errors.message : null}
      </div>


      <button
        disabled={formik.isSubmitting}
        className='buttonContact'
        type="submit">
        Enviar
      </button>
    </form>
  );
};