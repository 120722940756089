import imageProductBox from "@/assets/images/old/product-box.png";

export const Product = () => {
  return (
    <section className="box-section" style={{marginTop: 30}}>
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <img src={imageProductBox} alt="" className="product-box" />
            </div>
            <div className="col-md-6 big-border">
                <h2>
                    O primeiro benchmarking inteligente de comunicação 
                    {' '}<b>exclusivo para o setor público!</b>
                </h2>
                <ul>
                    <li><b>Crie conteúdo</b> a partir do que as outras instituições estão publicando</li>
                    <li><b>Identifique tendências</b> e assuntos de maior interesse da população</li>
                    <li><b>Compare o desempenho</b> de sua instituição com outras similares</li>
                </ul>
            </div>
        </div>
    </div>
</section>
  );
};
