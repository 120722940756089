import styled from "styled-components";

interface ContainerProps {
  isFocused: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.onPrimary};
  padding: 0.1rem 0.5rem .1rem .5rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  @media only screen and (min-width: 1025px) {
    /* width: ${(props) => (props.isFocused ? "300px" : "260px")} ; */
  }

  input {
    border: unset;
    background: transparent;
    color: ${(props) => props.theme.colors.onBackground};
    padding: 0.5rem;
    flex: 1;
  }

  button {
    background: transparent;
    border: unset;
    cursor: pointer;
    margin-right: 15px;
  }
`;
