import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { State } from "@/store";

import { FilterContextProvider } from "@/contexts/FilterContext";

import { FeedPage } from "@/pages/Feed";
import { Login } from "@/pages/Login";
import { Site } from "@/pages/Site";
import { Register } from "@/pages/Register";
import { ResetPassword } from "@/pages/ResetPassword";
import { Profile } from "@/pages/Profile";
import { RetrievePassword } from "@/pages/RetrievePassword";

interface IRouteProps {
  component: React.ComponentType;
  path?: string;
  isAuth: boolean;
}

const PrivateFeedRoute = ({
  component: RouteComponent,
  isAuth,
}: IRouteProps) => {
  if (isAuth) {
    return <RouteComponent />;
  } else {
    return <Navigate to="/" replace />;
  }
};

const PrivateLoginRoute = ({
  component: RouteComponent,
  isAuth,
}: IRouteProps) => (!isAuth ? <RouteComponent /> : <Navigate to="/feed" />);

export function AppRoutes() {
  const { data } = useSelector((state: State) => state.user);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          caseSensitive={true}
          element={
            <PrivateLoginRoute
              isAuth={data.access_token !== ""}
              component={Site}
            />
          }
        />

        <Route
          path="/login"
          caseSensitive={true}
          element={
            <PrivateLoginRoute
              isAuth={data.access_token !== ""}
              component={Login}
            />
          }
        />

        <Route
          path="/feed"
          element={
            <FilterContextProvider>
              <PrivateFeedRoute
                isAuth={data.access_token !== ""}
                component={FeedPage}
              />
            </FilterContextProvider>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateFeedRoute
              isAuth={data.access_token !== ""}
              component={Profile}
            />
          }
        />

        <Route
          path="/register"
          element={
            <PrivateLoginRoute
              isAuth={data.access_token !== ""}
              component={Register}
            />
          }
        />

        <Route
          path="/retrieve-password"
          element={
            <PrivateLoginRoute
              isAuth={data.access_token !== ""}
              component={RetrievePassword}
            />
          }
        />

        <Route
          path="/reset-password/:token"
          element={
            <PrivateLoginRoute
              isAuth={data.access_token !== ""}
              component={ResetPassword}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
