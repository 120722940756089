import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.colors.onPrimary};
  height: max-content;
  width: 98%;
  max-width: 98%;
  margin: 0 auto;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  // z-index: 1;

  .btn-favorite {
    opacity: 0;
    position: relative;
    z-index: 4;
  }

  &:hover {
    .btn-favorite {
      margin-top: 3px;
      opacity: 1;
    }
  }

  &:not(&.favorited) {

  }
`;

interface PropsThumb {
  hasThumbnail: boolean | undefined | string | null;
  is_video?: boolean;
}

export const Thumb = styled.div<PropsThumb>`
  position: relative;
  overflow: hidden;
  min-height: ${(props) => (props.hasThumbnail ? "170px" : "0px")};

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: ${(props) => (props.is_video ? "block" : "none")};
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.9;
    &:hover {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }

  .social-brand {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  iframe {
    width: 100%;
    height: 200px;
  }
`;

interface RateItemProps {
  tooltip: string;
}

export const RateItem = styled.button<RateItemProps>`
  border: unset;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  span {
    color: ${(props) => props.theme.colors.onBackground};
    display: inline-block;
    margin-left: 0.5rem;
  }
  .rate-icon {
    color: ${(props) => props.theme.colors.onBackground};
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    span {
      color: ${(props) => props.theme.colors.primary};
    }
    .tooltip {
      visibility: initial;
      opacity: 1;
    }
  }
`;

export const Tooltip = styled.div`
  visibility: hidden;
  width: max-content;
  background-color: ${(props) => props.theme.colors.onBackground};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.5rem 0.7rem;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.onBackground} transparent
      transparent transparent;
  }
`;

export const TooltipFav = styled.div`
  visibility: hidden;
  width: max-content;
  background-color: ${(props) => props.theme.colors.onBackground};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: -90%;
  margin-left: -105px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.3rem 0.5rem;
  top: -24px;
  height: 25px;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 85%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.onBackground} transparent
      transparent transparent;
  }
`;

export const ContentText = styled.div`
  padding: 1rem;
  padding-bottom: 0;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.onBackground};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

export const Rates = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
`;

export const TopCard = styled.div`
  border-top: 1px solid #e7ebef;
  padding: 0.5rem 1rem;
  color: ${(props) => props.theme.colors.onBackground};
  display: flex;
  align-items: center;

  
  .date {
    font-size: 14px;
    margin-top: 2px;
    span {
      margin-left: 5px;
    }
  }
`;

export const TopCardImage = styled.div`
  margin-right: 10px;
  max-width: 32px;

  img {
    max-width: 100%;
    border-radius: 100px;
  }
  span {
    margin-left: 0.5rem;
  }
`;

export const TopCardActions = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

`;

export const AuthorName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 320px;
`;

export const FavoriteButton = styled.button.attrs({
  type: "button",
})`
  background: transparent;
  transition: opacity 0.3s ease;
  opacity: 1;
  border: unset;
  cursor: pointer;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
`;
