import Logo from "@/assets/images/logo-color.png";
import { Link } from "react-router-dom";

export const LogoLink = () => {
  return (
    <Link to="/">
      <img src={Logo} alt="Social Media Gov" />
    </Link>
  );
};
