import imageR2oh from "@/assets/images/old/r2oh.png";
import imageLogo from "@/assets/images/old/logo.png";

export const FooterBg = () => {
  return (
    <section className="bg-footer">
      <div className="wrapper">
        <div className="col-md-4">
          <div className="block">
            <div className="logo-container">
              <img className="logo" src={imageLogo} alt="Social Media Gov" />
            </div>
          </div>
        </div>
        <div className="col-md-4">
            <h4 className="title">
              Copyright © 2022 R2OH Digital.
              <br/>Todos os direitos reservados.
              <br/>Email: contato@socialmediagov.com.br
            </h4>
        </div>            
          <div className="col-md-3">
            <div className="poweredby">
                    <span>Powered by</span>
                    <a href="https://r2oh.com.br/" target='_blank' rel="noreferrer" >
                      <img src={imageR2oh} alt="r2oh" />
                    </a>
                </div>         
        </div>
      </div>
    </section>
  );
};
