import styled from "styled-components";

import logo from "../../assets/images/logo-color.png";
interface ContainerProps {
  menuMobileActive: boolean;
}

export const ContainerDatePicker = styled.div`
  margin-bottom: 30px;
  margin-top: 15px;
`;

export const Container = styled.div<ContainerProps>`
  min-width: 305px;
  width: 15%;
  background: ${(props) => props.theme.colors.onPrimary};
  height: 100vh;
  padding: 5px 5px 0px 10px;
  overflow-y: scroll;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 25%);
  position: relative;
  display: flex;
  flex-direction: column;
  scrollbar-color: ${(props) => props.theme.colors.primary} transparent;
  scrollbar-width: thin;

  .start-date-field {
    margin-bottom: 15px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 30px;
  }

  .search {
    background: #edf1f5;
    width: 100%;
    // box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 25%);
    border-radius: 25px;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  }
  .order-buttons {
    display: none;
  }
  .filters {
    > span {
      font-weight: 700;
      color: ${(props) => props.theme.colors.onBackground};
      display: block;
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    transition: all 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    transform: ${(props) =>
      props.menuMobileActive ? "translateX(0%)" : "translateX(-100%)"};
    width: 100%;
    max-width: initial;
    height: 100vh;
    overflow-y: initial;
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 6;

    .search {
      margin-bottom: 30px;
      display: flex;
    }
    .order-buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      align-items: flex-start;

      &__list {
        flex-direction: column;
        display: flex;
        .order-button {
          margin-left: 0;
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

type WrapperProps = {
  isLoading: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  flex: 1;
  opacity: ${(props) => (props.isLoading ? 0.8 : 1)};
  pointer-events: ${(props) => (props.isLoading ? "none" : "auto")};
  cursor: ${(props) => (props.isLoading ? "progress" : "")};
  transition: all 0.3s ease;
  @media only screen and (max-width: 1024px) {
    overflow-y: scroll;
    padding: 15px 15px 0 15px;
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.primary};
      border-radius: 30px;
    }
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label:first-of-type {
    margin: 0;
  }
`;

export const WrapperCheckTextList = styled.div`
  // background: #EDF1F5;
  // box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 25%);
  width: 100%;
  border-radius: 4px;
  max-height: 35vh;
  overflow-y: scroll;
  margin-bottom: 15px;
  scrollbar-color: ${(props) => props.theme.colors.primary} transparent;
  scrollbar-width: thin;

  @media screen and (max-width: 1366px) {
    max-height: 20vh;
    padding-right: 10px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-track {
    background: red;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 30px;
  }

  .btn-sphere-name:first-of-type {
    // padding-top: 5px;
  }

  .btn-sphere-name:last-of-type {
    // padding-bottom: 5px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 30px;
  }
`;

export const CheckTextList = styled.ul`
  .check-text {
    margin: 0.5rem;
  }

  .is--open {
    /* background: ${(props) => props.theme.colors.background}; */
  }
`;

export const LogoButton = styled.button.attrs({
  type: "button",
})`
  background: transparent;
  border: unset;
  padding-right: 15px;
`;

export const MobileCloseMenu = styled.button.attrs({
  type: "button",
})`
  display: none;
  background: transparent;
  border: unset;
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 1.5rem;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;

export const ButtonSphereName = styled.button.attrs({
  type: "button",
})`
  background: transparent;
  border: unset;
  color: ${(props) => props.theme.colors.primary};
  display: block;
  font-weight: 600;
  // margin-bottom: .8rem;
  font-size: 15px;
  width: 100%;
  text-align: left;
  transition: all 0.3s ease;
  padding: 0.5rem 0.5rem 0.3rem 0rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: #edf1f5;

    /* color: #fff; */
  }
`;

export const ContainerCollapse = styled.div`
  padding-left: 12px;
`;

export const LabelDate = styled.span`
  min-width: 80px;
`;

export const ButtonFilterByFavorites = styled.button`
  background: transparent;
  border: unset;
  // box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 25%);
  // background: #EDF1F5;
  border-radius: 6px;
  width: 98%;
  padding: 5px;
  padding-left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  color: #54667a;
  svg {
    margin-right: 5px;
  }
`;

export const Logo = styled.img.attrs((props) => ({
  src: logo,
}))`
  margin: 0.5rem;
  margin-left: 5px;
  margin-bottom: 1rem;
  // width: 290px;
  @media screen and (max-width: 1024px) {
    width: 200px;
  }
`;
