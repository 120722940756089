import imageFacebook from "@/assets/images/old/facebook.png"
import imageInstagram from "@/assets/images/old/instagram.png"
import imageTwitter from "@/assets/images/old/twitter.png"
import imageYoutube from "@/assets/images/old/youtube.png"

import { Contact } from "@/components/Contact";

export const Footer = () => {
  return (
    <section className="footer-section">
    <div className="wrapper">
        <div className="col-md-6">
            <div className="block">
                <h3 className="title">
                    Instituições Públicas analisadas:
                </h3>
                <p>
                Prefeituras - Governos Estaduais - Governo Federal<br />
                Legislativos Estaduais - Congresso Nacional<br />
                Ministérios Públicos - Tribunais de Contas  <br />
                Tribunais de Justiça - Universidades Públicas <br />
                </p>
            </div>
            <div className="block">
                <h3 className="title">Mídias Sociais analisadas:</h3>
                <div className="social-logos">
                    <img src={imageFacebook} alt="Facebook" />
                    <img src={imageInstagram} alt="Instagram" />
                    <img src={imageTwitter} alt="Twitter" />
                    <img src={imageYoutube} style={{height: 26}} alt="YouTube" />
                </div>
            </div>
            <div className="block">
                <h3 className="title">Curadoria:</h3>
                <p>
                    Além do acesso à ferramenta de benchmarking, sua instituição 
                    receberá nosso conteúdo semanal por e-mail com cases de fracasso
                    e de sucesso, análise de tendências, ebooks e muito mais!
                </p>
            </div>            
        </div>
        <div className="col-md-6 fromContact">
            <div className="block">
                <h3 className="title">Entre em contato. Tire suas dúvidas. CONTRATE AGORA!</h3>
                <Contact/>
            </div>
        </div>
    </div>
</section>
    );
};
