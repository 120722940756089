import { useState, useEffect } from "react";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";

import { LogoLink } from "@/components/LogoLink";

import { actionLoginRequest, actionLogout } from "@/store/ducks/user/actions";
import { State } from "@/store";

import { Container, Form, ActionButton, LinkButton, Loading } from "./styles";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const { error, loading, errorMessage } = useSelector(
    ({ user }: State) => user
  );

  function handleSubmit() {
    dispatch(
      actionLoginRequest({
        email: email,
        password: password,
      })
    );
  }

  useEffect(() => {
    if (error) {
      toast.error(errorMessage);
      dispatch(actionLogout());
    }
  }, [error, dispatch, errorMessage]);

  return (
    <Container>
      <LogoLink />
      <Form>
        <TextField
          id="standard-basic"
          label="E-mail"
          variant="standard"
          type="text"
          value={email}
          onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          id="standard-basic"
          label="password"
          variant="standard"
          type="password"
          value={password}
          onKeyUp={(e) => e.key === "Enter" && handleSubmit()}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <ActionButton type="button" onClick={handleSubmit}>
          {loading ? <Loading /> : "Entrar"}
        </ActionButton>

        <LinkButton to="/register"> Registrar </LinkButton>

        <LinkButton to="/retrieve-password"> Recuperar senha </LinkButton>
      </Form>
    </Container>
  );
};
