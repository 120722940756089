import { Reducer } from "redux";
import { CategorysSate, ActionTypesCategory } from "./types";
import { ActionTypesUser } from "@/store/ducks/user/types";

const INITIAL_STATE: CategorysSate = {
  spheres: {},
  error: false,
  loading: false,
};

const reducer: Reducer<CategorysSate> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypesCategory.GET_GATEGORYS_REQUEST:
      return { ...state, loading: true };
    case ActionTypesCategory.GET_GATEGORYS_SUCCESS:
      return {
        spheres: action.payload,
        loading: false,
        error: false,
      };
    case ActionTypesCategory.GET_GATEGORYS_FAIL:
      return { ...state, loading: false, error: true };
    case ActionTypesUser.USER_LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
