import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "@/helper/api";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

import {
  Container,
  Form,
  ActionButton,
  FormRow,
  Loading,
} from "@/pages/Login/styles";

import { AxiosError } from "axios";

import { LogoLink } from "@/components/LogoLink";

interface IFormInputs {
  email: string;
  password: string;
  password_confirmation: string;
}

interface ErrorResetPassword {
  message: string;
  errors: {
    email: string[];
  };
}

const resetFormSchema = yup.object().shape({
  email: yup.string().email("E-mail inválido").required("Email é obrigatório"),
  password: yup
    .string()
    .min(8, "A senha deve conter no mínimo 8 caracteres")
    .required("Senha é obrigatória"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "As Senhas não conferem")
    .required("Confirmação de senha obrigatória"),
});

export const ResetPassword = () => {
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(resetFormSchema),
  });

  async function onSubmit({
    email,
    password,
    password_confirmation,
  }: IFormInputs) {
    try {
      if (token) {
        setIsLoading(true);
        await resetPassword({
          email: email,
          password: password,
          password_confirmation: password_confirmation,
          token: token,
        }).finally(() => setIsLoading(false));
        toast.success("Sua senha foi alterada com sucesso");
        navigate("/");
      }
      toast.error("Token inválido");
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.data) {
        const errorRegister = err.response.data as ErrorResetPassword;
        toast.error(errorRegister.message);
        if (errorRegister) {
          Object.entries(errorRegister.errors).forEach(([key, value]) => {
            value.map((message) => toast.error(message));
          });
        }
      }
    }
  }

  return (
    <Container>
      <LogoLink />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <TextField
            label="E-mail"
            variant="standard"
            type="email"
            {...register("email")}
          />
          <p>{errors.email?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="Senha"
            variant="standard"
            type="password"
            {...register("password")}
          />
          <p>{errors.password?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="Confirme a senha"
            variant="standard"
            type="password"
            {...register("password_confirmation")}
          />
          <p>{errors.password_confirmation?.message}</p>
        </FormRow>

        <ActionButton type="submit">
          {isLoading ? <Loading /> : "Resetar senha"}
        </ActionButton>
      </Form>
    </Container>
  );
};
