import { useState, useContext, useEffect, memo } from "react";
import { useTheme } from "styled-components";
import Collapse from "@kunukn/react-collapse";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR'

import { socialNetworks } from "@/constants/socialNetworks";

import {
  faWindowClose,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as IcStar } from "../../assets/images/icons/ic-star.svg";
import { ReactComponent as IcStarFilled } from "../../assets/images/icons/ic-star-filled.svg";

import { CheckIcon } from "@/components/CheckIcon";
import { CheckText } from "@/components/CheckText";
import { Search } from "@/components/Search";
import { OrderButtons, OrderItem } from "@/components/OrderButtons";

import { FilterContext } from "@/contexts/FilterContext";

import {
  Container,
  CheckTextList,
  FlexContainer,
  Logo,
  MobileCloseMenu,
  Wrapper,
  WrapperCheckTextList,
  LogoButton,
  ButtonSphereName,
  ContainerCollapse,
  ButtonFilterByFavorites,
  ContainerDatePicker,
} from "./styles";

import { Category, GroupsBySphere } from "@/store/ducks/categorys/types";

import format from "date-fns/format";
import sub from "date-fns/sub";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TextField } from "@mui/material";
import { parseISO } from "date-fns";


interface AsideMenuProps {
  menuMobileActive: boolean;
  onCloseMenu: () => void;
  isLoading: boolean;
  spheres: GroupsBySphere;
}

const AsideMenuComponent = ({
  menuMobileActive,
  onCloseMenu,
  spheres,
  isLoading,
}: AsideMenuProps) => {
  const theme = useTheme();
  const { filters, setFilters } = useContext(FilterContext);

  const [sphereNames, setSphereNames] = useState<
    { key: string; isOpen: boolean, allIsSelected: boolean }[]
  >([]);

  useEffect(() => {
    let arrayOfSphereNames: { key: string; isOpen: boolean, allIsSelected: false }[] = [];

    Object.keys(spheres).forEach((key) => {
      arrayOfSphereNames.push({ key, isOpen: false, allIsSelected: false });
    });
    setSphereNames(arrayOfSphereNames);
  }, [spheres]);

  function handleCollapse(index: number) {
    const aux = [...sphereNames];
    aux[index].isOpen = !aux[index].isOpen;
    setSphereNames(aux);
  }

  function handleIsSelected(index: number) {
    const aux = [...sphereNames];
    aux[index].allIsSelected = !aux[index].allIsSelected;
    setSphereNames(aux);
  }

  function handleSources(data: { active: boolean; value: string }) {
    const { active, value } = data;
    const contextSources = filters.source ? filters.source?.split(",") : [];
    let oldSources = [...contextSources];

    let isOnSources = oldSources.indexOf(value);

    if (isOnSources === -1) {
      oldSources.push(value);
    } else {
      !active && oldSources.splice(isOnSources, 1);
    }

    setFilters({ ...filters, page: 1, source: oldSources.join(",") });
  }

  function handleCategorys(data: {
    active: boolean;
    value: string;
    id: string;
    sphereNamesIndex: number
  }) {
    const { active, value, sphereNamesIndex } = data;
    const { category } = filters;

    const contextCategories = category ? category?.split(",") : [];

    let oldCategorys = [...contextCategories];

    let isOnCategorys = oldCategorys.indexOf(value);

    if (isOnCategorys === -1) {
      oldCategorys.push(value);
    } else {
      !active && oldCategorys.splice(isOnCategorys, 1);
    }

    if(sphereNames[sphereNamesIndex].allIsSelected){
      handleIsSelected(sphereNamesIndex)
    }

    setFilters({
      ...filters,
      page: 1,
      category: oldCategorys.join(","),
    });
  }

  function handleFilterByFavorites() {
    if (filters.favorite) {
      delete filters.favorite;
      setFilters({ ...filters, page: 1 });
      return;
    }

    setFilters({ ...filters, page: 1, favorite: true });
  }

  function handleSearchFilter(keyword: string) {
    setFilters({ ...filters, page: 1, keyword: keyword });
  }

  function handleOrderButtonsToFilter(data: OrderItem) {
    const filtersAux = { ...filters };
    if (data.id === 1) {
      delete filtersAux.sort_field;
      delete filtersAux.sort_order;
      setFilters({ ...filtersAux, page: 1, keyword: "" });
      return;
    }
    setFilters({
      ...filtersAux,
      page: 1,
      sort_field: data.value,
      sort_order: data.order,
    });
  }

  function handleLogoButtonClick() {
    const currentDayMinusSeven = sub(new Date(), {
      days: 7,
    });

    setFilters({
      page: 1,
      per_page: 20,
      begin_date: format(currentDayMinusSeven, "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
      sort_field: "interactions",
      sort_order: "desc",
    });
  }

  function handleSelectCategoryByBlock(key: string, isChecked: boolean, sphereNameIndex: number) {

    const { category } = filters;

    const contextCategories = category ? category?.split(",") : [];

    let oldCategorys = [...contextCategories];

    !sphereNames[sphereNameIndex].isOpen && handleCollapse(sphereNameIndex)

    handleIsSelected(sphereNameIndex)

    spheres[key].forEach(value => {
      if(isChecked){
        !category?.includes(value.uuid) && oldCategorys.push(value.uuid)
      } else {
        const index = oldCategorys.indexOf(value.uuid);
        if(index !== -1) {
          oldCategorys.splice(index, 1);
        }
      }
    })


    setFilters({
      ...filters,
      page: 1,
      category: oldCategorys.join(","),
    });
  }

  return (
    <Container menuMobileActive={menuMobileActive}>
      <MobileCloseMenu type="button" onClick={onCloseMenu}>
        <FontAwesomeIcon color={theme.colors.primary} icon={faWindowClose as IconProp} />
      </MobileCloseMenu>
      <Wrapper isLoading={isLoading}>
        <LogoButton onClick={handleLogoButtonClick}>
          <Logo />
        </LogoButton>
        <div className="filters">
          <Search
            isOnAsideMenu
            onHandleSearch={(value) => handleSearchFilter(value)}
          />
          <OrderButtons onOrder={(data) => handleOrderButtonsToFilter(data)} />
          <span> Data de Publicação </span>
          <ContainerDatePicker>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} >
              <DesktopDatePicker
                label="Início"
                inputFormat="dd/MM/yyyy"
                value={filters.begin_date ? parseISO( filters.begin_date) : ""}
                onChange={(e) => {
                  setFilters({ ...filters, begin_date: e ? format( new Date(e), "yyyy-MM-dd") : "" });
                }}
                renderInput={(params) => <TextField className="start-date-field" {...params  } />}
              />

              <DesktopDatePicker
                label="Fim"
                className="date-field"
                inputFormat="dd/MM/yyyy"
                value={filters.end_date ? parseISO(filters.end_date) : ""}
                onChange={(e) => {
                  setFilters({ ...filters, end_date: e ? format(new Date(e), "yyyy-MM-dd") : "" });
                }}
                renderInput={(params) => <TextField {...params  } />}
              />
            </LocalizationProvider>
          </ContainerDatePicker>
          <span style={{ marginTop: "10px" }}> Redes Sociais </span>
          <FlexContainer>
            {socialNetworks.map(({ value, id, checkedColor, icon }) => (
              <CheckIcon
                key={id}
                checkedColor={theme.colors[checkedColor]}
                name="source[]"
                value={value}
                checked={filters.source?.includes(value)}
                id={id}
                icon={icon}
                onChangeHandler={(active) =>
                  handleSources({ active, value: value })
                }
              />
            ))}
          </FlexContainer>

          <span> Categorias </span>
          <WrapperCheckTextList>
            <CheckTextList>
              {sphereNames.map((key, sphereNamesIndex) => (
                <div
                  key={key.key}
                  className={`box-shadow ${key.isOpen ? "is--open" : ""}`}
                >
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      checked={key.allIsSelected}
                      type="checkbox"
                      style={{marginRight: 10}}
                      key={key.key} onChange={(e) => handleSelectCategoryByBlock(key.key, e.target.checked, sphereNamesIndex)}
                      />

                    <ButtonSphereName
                      className="btn-sphere-name"
                      onClick={() => handleCollapse(sphereNamesIndex)}
                      >
                      {key.key}
                      <FontAwesomeIcon
                        color={theme.colors.onBackground}
                        icon={key.isOpen ? faCaretDown as IconProp : faCaretRight as IconProp}
                        />
                    </ButtonSphereName>
                  </div>
                  {spheres[key.key].map((option: Category) => (
                    <ContainerCollapse key={option.uuid}>
                      <Collapse isOpen={key.isOpen}>
                        <CheckText
                          checked={filters.category?.includes(option.uuid)}
                          onHandleChecked={(active) =>
                            handleCategorys({
                              active,
                              value: option.uuid,
                              id: option._id,
                              sphereNamesIndex: sphereNamesIndex
                            })
                          }
                          key={option._id}
                          option={option}
                        />
                      </Collapse>
                    </ContainerCollapse>
                  ))}
                </div>
              ))}
            </CheckTextList>
          </WrapperCheckTextList>

          <span> Favoritos </span>
          <ButtonFilterByFavorites onClick={handleFilterByFavorites}>
            {filters.favorite ? (
              <IcStarFilled width={20} height={20} />
            ) : (
              <IcStar width={20} height={20} />
            )}
            Meus Favoritos
          </ButtonFilterByFavorites>
        </div>
      </Wrapper>
    </Container>
  );
};

export const AsideMenu = memo(AsideMenuComponent, (prevProps, nextProps) => {
  return Object.is(prevProps, nextProps);
});
