import React, { createContext, useCallback, useEffect, useState } from "react";

import sub from "date-fns/sub";

import { useDispatch, useSelector } from "react-redux";
import { State } from "@/store";
import {
  actionsClearMentions,
  actionsGetMentions,
} from "@/store/ducks/mentions/actions";
import { Mention, MentionsRequestParams } from "@/store/ducks/mentions/types";
import format from "date-fns/format";

interface FilterContextData {
  filters: MentionsRequestParams;
  mentions: Mention[];
  setFilters: (filters: MentionsRequestParams) => void;
}

const currentDayMinusSeven = sub(new Date(), {
  days: 7,
});

const DEFAULT_FILTERS = {
  page: 1,
  per_page: 20,
  begin_date: format(currentDayMinusSeven, "yyyy-MM-dd"),
  end_date: format(new Date(), "yyyy-MM-dd"),
  sort_field: "interactions",
  sort_order: "desc",
};

export const FilterContext = createContext<FilterContextData>({
  filters: DEFAULT_FILTERS,
} as FilterContextData);

interface Props {
  children: React.ReactNode;
}

export const FilterContextProvider = ({ children }: Props) => {
  const { list, loading } = useSelector((state: State) => state.mentions);
  const dispatch = useDispatch();
  const [mentionsList, setMentionsList] = useState<Mention[]>([]);
  const [filters, setFilters] =
    useState<MentionsRequestParams>(DEFAULT_FILTERS);

  useEffect(() => {
    if (!loading) {
      const newFilters = { ...filters };

      if (!newFilters.sort_field) {
        delete newFilters.sort_field;
        delete newFilters.sort_order;
      }

      dispatch(actionsGetMentions(newFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dispatch]);

  useEffect(() => {
    setMentionsList(list);
  }, [list, filters.page, dispatch]);

  const handleSetFilters = useCallback(
    (filters: MentionsRequestParams) => {
      if (!loading) {
        filters.page === 1 && dispatch(actionsClearMentions());
        setFilters(filters);
      }
    },
    [dispatch, loading]
  );

  return (
    <FilterContext.Provider
      value={{
        filters,
        mentions: mentionsList,
        setFilters: (filters) => handleSetFilters(filters),
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
