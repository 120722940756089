import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { orderByList } from "@/constants/orderByList";

import { useSelector } from "react-redux";
import { State } from "@/store";
import { FilterContext } from "@/contexts/FilterContext";

import { Tooltip } from "@/components/Card/styles";
import { Container, OrderButton } from "./styles";

export type OrderItem = {
  id: number;
  value: string;
  title: string;
  active: boolean;
  order: string;
};
interface IOrderButton {
  onOrder: (data: OrderItem) => void;
}

export const OrderButtons = ({ onOrder }: IOrderButton) => {
  const { loading } = useSelector((state: State) => state.mentions);
  const { filters } = useContext(FilterContext);

  function handleOnClickButton(data: OrderItem) {
    console.log(filters.sort_field === data.value);
    if (filters.sort_field !== data.value) {
      onOrder({
        ...data,
        order: "desc",
      });
    } else {
      onOrder({
        ...data,
        order: filters.sort_order === "asc" ? "desc" : "asc",
      });
    }
  }

  return (
    <Container className="order-buttons">
      <span> Ordenar por</span>
      <div className="order-buttons__list">
        {orderByList.map(({ active, id, title, value, order, tooltip }) => (
          <OrderButton
            isLoading={loading}
            type="button"
            onClick={() => {
              handleOnClickButton({ active, id, title, value, order });
            }}
            key={id}
            className={`${
              filters.sort_field === value ? "is-active" : ""
            }  order-button`}
          >
            {title}
            {filters.sort_field === value && (
              <FontAwesomeIcon
                className="order-button-icon"
                icon={
                  filters.sort_field === value && filters.sort_order === "asc"
                    ? faChevronUp
                    : faChevronDown
                }
              />
            )}
            {tooltip ? (
              <Tooltip className="tooltip"> {tooltip} </Tooltip>
            ) : null}
          </OrderButton>
        ))}
      </div>
    </Container>
  );
};
