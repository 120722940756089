import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";

import { toast } from "react-toastify";

import { register as apiRegister } from "@/helper/api";

import { AxiosError } from "axios";

import {
  Container,
  Form,
  ActionButton,
  FormRow,
  LinkButton,
  Loading,
} from "@/pages/Login/styles";
import { useNavigate } from "react-router-dom";
import { LogoLink } from "@/components/LogoLink";
interface IFormInputs {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  entity: string;
}

interface ErrorRegister {
  message: string;
  errors: {
    email: string[];
  };
}

const registerFormSchema = yup.object().shape({
  name: yup.string().required("Nome é obrigatório"),
  email: yup.string().email("E-mail inválido").required("Email é obrigatório"),
  password: yup
    .string()
    .min(8, "A senha deve conter no mínimo 8 caracteres")
    .required("Senha é obrigatória"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As Senhas não conferem")
    .required("Confirmação de senha obrigatória"),
  entity: yup.string().min(2, "Instituição inválida").required(),
});

export const Register = () => {
  const [isLoadingRegister, setisLoadingRegister] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(registerFormSchema),
  });

  const onSubmit = async ({ name, email, password, entity }: IFormInputs) => {
    try {
      setisLoadingRegister(true);
      const response = await apiRegister({
        name,
        email,
        password,
        entity,
      }).finally(() => setisLoadingRegister(false));
      toast.success("Cadastro realizado com sucesso");
      navigate("/");
      return response;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.data) {
        const errorRegister = err.response.data as ErrorRegister;
        toast.error(errorRegister.message);
        if (errorRegister) {
          Object.entries(errorRegister.errors).forEach(([key, value]) => {
            value.map((message) => toast.error(message));
          });
        }
      }
    }
  };

  return (
    <Container>
      <LogoLink />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <TextField
            label="Nome"
            variant="standard"
            type="text"
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="E-mail"
            variant="standard"
            type="text"
            {...register("email")}
          />
          <p>{errors.email?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="Senha"
            variant="standard"
            type="password"
            {...register("password")}
          />
          <p>{errors.password?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="Confirmar Senha"
            variant="standard"
            type="password"
            {...register("confirmPassword")}
          />
          <p>{errors.confirmPassword?.message}</p>
        </FormRow>
        <FormRow>
          <TextField
            label="Instituição"
            variant="standard"
            type="text"
            {...register("entity")}
          />
          <p>{errors.entity?.message}</p>
        </FormRow>
        <ActionButton type="submit">
          {isLoadingRegister ? <Loading /> : "Cadastrar"}
        </ActionButton>
        <LinkButton to="/"> Voltar para o login </LinkButton>
      </Form>
    </Container>
  );
};
