
import { SiteHeader } from "@/components/SiteHeader";

import { Footer } from "@/components/Footer";

import { Features } from "@/components/Features";

import { Product } from "@/components/Product";

import { FooterBg } from "@/components/FooterBg";


if (window?.location.pathname === '/')
    require('./site.scss')

// import './site.scss'

export const Site = () => {
  return (
    <div className="site">
      <script
      src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"></script>

        <SiteHeader/>
        <Product />
        <Features />
        <Footer />
        <FooterBg />
    </div>        
  );
};

