import { useEffect, useState, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AsideMenu } from "@/components/AsideMenu";
import { Feed } from "@/components/Feed";
import { Header } from "@/components/Header";

import { State } from "@/store";
import { actionGetCategorys } from "@/store/ducks/categorys/actions";

import { FilterContext } from "@/contexts/FilterContext";

import { Container } from "./styles";

export const FeedPage = () => {
  const { filters, setFilters, mentions } = useContext(FilterContext);

  const dispatch = useDispatch();

  const { loading } = useSelector((state: State) => state.mentions);
  const { spheres } = useSelector((state: State) => state.categorys);

  const [isMenuMobileActive, setIsMenuMobileActive] = useState(false);

  const handleOnCloseMenu = useCallback(() => {
    setIsMenuMobileActive(false);
  }, []);

  useEffect(() => {
    dispatch(actionGetCategorys());
  }, [dispatch]);

  return (
    <Container>
      <AsideMenu
        onCloseMenu={handleOnCloseMenu}
        menuMobileActive={isMenuMobileActive}
        isLoading={loading}
        spheres={spheres}
      />
      <div style={{ flex: 1, width: "85%" }}>
        <Header
          onMenuMobileClick={() => setIsMenuMobileActive(true)}
          onHandleSearch={(value) => setFilters({ ...filters, keyword: value })}
        />
        <Feed mentions={mentions} isLoading={loading} />
      </div>
    </Container>
  );
};

export default FeedPage;
