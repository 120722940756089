import { useState } from "react";

import TextField from "@mui/material/TextField";

import { toast } from "react-toastify";

import { retrievePasswordEmail } from "@/helper/api";

import {
  Container,
  Form,
  ActionButton,
  LinkButton,
  Loading,
} from "@/pages/Login/styles";
import { AxiosError } from "axios";
import { LogoLink } from "@/components/LogoLink";

export const RetrievePassword = () => {
  const [email, setEmail] = useState("");

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  async function handleEmail() {
    if (email) {
      try {
        setIsSendingEmail(true);
        await retrievePasswordEmail(email).finally(() =>
          setIsSendingEmail(false)
        );
        toast.success(
          "Enviamos um link para recuperação da senha no e-mail informado"
        );
      } catch (error) {
        const err = error as AxiosError;
        const message = err.response?.data.message
          ? err.response?.data.message
          : "Não foi possível enviar o email";

        toast.error(message);
        if (err.response?.data.errors) {
          Object.keys(err.response?.data.errors).forEach((key) => {
            toast.error(err.response?.data.errors[key][0]);
          });
        }
      }
    }
  }

  return (
    <Container>
      <LogoLink />
      <Form onSubmit={(e) => e.preventDefault()}>
        <TextField
          id="standard-basic"
          label="E-mail"
          variant="standard"
          type="text"
          value={email}
          onKeyUp={(e) => e.key === "Enter" && handleEmail()}
          onChange={(e) => setEmail(e.target.value)}
        />

        <ActionButton type="button" onClick={handleEmail}>
          {isSendingEmail ? <Loading /> : "Enviar email"}
        </ActionButton>

        <LinkButton to="/">Voltar para o login</LinkButton>
      </Form>
    </Container>
  );
};
