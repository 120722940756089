import {
  faYoutube,
  faFacebook,
  faInstagram,
  faTwitter,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";

type SocialNetwork = {
  value: string;
  id: string;
  checkedColor: "facebook" | "instagram" | "twitter" | "youtube";
  icon: IconDefinition;
  isChecked: boolean;
};

export const socialNetworks: SocialNetwork[] = [
  {
    value: "facebook",
    id: "filter-facebook",
    checkedColor: "facebook",
    icon: faFacebook,
    isChecked: false,
  },
  {
    value: "instagram",
    id: "filter-instagram",
    checkedColor: "instagram",
    icon: faInstagram,
    isChecked: false,
  },
  {
    value: "twitter",
    id: "filter-twitter",
    checkedColor: "twitter",
    icon: faTwitter,
    isChecked: false,
  },
  {
    value: "youtube",
    id: "filter-youtube",
    checkedColor: "youtube",
    icon: faYoutube,
    isChecked: false,
  },
];
