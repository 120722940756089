import { call, put, select, StrictEffect } from "redux-saga/effects";

import { categorys } from "@/helper/api";

import { State } from "@/store/index";

import { actionGetCategorysSuccess, actionGetCategorysFail } from "./actions";
import { Category, GroupsBySphere } from "./types";

export function* sagaGetCategorys(): Generator<StrictEffect, void, any> {
  try {
    const getUser = (state: State) => state.user;
    const { data }: ReturnType<typeof getUser> = yield select(getUser);
    const { access_token } = data;

    const response = yield call(categorys, access_token);
    const list = response.data as Category[];

    let groupsBySphere: GroupsBySphere = list.reduce(
      (prevState, currentValue) => {
        prevState[currentValue.esfera] = prevState[currentValue.esfera] || [];
        prevState[currentValue.esfera].push(currentValue);
        return prevState;
      },
      Object.create(null)
    );

    yield put(actionGetCategorysSuccess(groupsBySphere));
  } catch (error) {
    yield put(actionGetCategorysFail());
  }
}
