import { InputHTMLAttributes } from "react";

import { Container, CheckboxContainer } from "./styles";
import { Category } from "@/store/ducks/categorys/types";
interface CheckTextProps extends InputHTMLAttributes<HTMLInputElement> {
  option: Category;
  onHandleChecked: (active: boolean) => void;
}

export const CheckText = ({
  option,
  onHandleChecked,
  ...rest
}: CheckTextProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  function handleOnChange(checked: boolean) {
    onHandleChecked(checked);
  }

  return (
    <Container className="check-text">
      <CheckboxContainer htmlFor={option._id}>
        <input
          onChange={(e) => handleOnChange(e.target.checked)}
          type="checkbox"
          id={option._id}
          {...rest}
        />
        <span>{option.name}</span>
      </CheckboxContainer>
    </Container>
  );
};
