import { darken } from "polished";
import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .3rem;
  padding-top: 15px;
  background: ${(props) => props.theme.colors.background};
  position: relative;
  z-index: 5;
  @media only screen and (max-width: 1024px) {
    padding: 1rem
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-right: 15px;
  margin-top: 3px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerUserAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  margin-right: 10px;
  button:first-of-type {
    margin-right: 10px;
  }
`;

export const UserName = styled.span`
  color: ${(props) => props.theme.colors.onBackground};
  margin-right: 1rem;
`;
export const MobileButton = styled.button`
  border: unset;
  background: transparent;
  display: none;
  cursor: pointer;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`;

export const ProfileButton = styled.button.attrs({
  type: "button",
})`
  border-radius: 2rem;
  padding: .5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: unset;
  background: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: ${(props) => darken(0.2, props.theme.colors.primary)};
  }
`;
