import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";

import { toast } from "react-toastify";

import { changePassword } from "@/helper/api";

import { AxiosError } from "axios";
import { useSelector } from "react-redux";
import { State } from "@/store";

import { LogoLink } from "@/components/LogoLink";

import {
  Container,
  Form,
  ActionButton,
  FormRow,
  LinkButton,
  Loading,
} from "@/pages/Login/styles";
interface IFormInputs {
  password_atual: string;
  password: string;
  password_confirmation: string;
}
interface ErrorChangePassword {
  message: string;
  errors: {
    password_atual: string;
  };
}

const registerFormSchema = yup.object().shape({
  password_atual: yup
    .string()
    .min(8, "A senha deve conter no mínimo 8 caracteres")
    .required("Senha é obrigatória"),
  password: yup
    .string()
    .min(8, "A senha deve conter no mínimo 8 caracteres")
    .required("Senha é obrigatória"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "As Senhas não conferem")
    .required("Confirmação de senha obrigatória"),
});

export const Profile = () => {
  const [isLoading, setisLoading] = useState(false);
  const { data } = useSelector(({ user }: State) => user);
  const { user, access_token } = data;
  const { name, email, _id } = user;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(registerFormSchema),
  });

  const onSubmit = async ({
    password,
    password_atual,
    password_confirmation,
  }: IFormInputs) => {
    try {
      setisLoading(true);
      const body = {
        password_atual,
        password,
        password_confirmation,
        email,
        _id,
      };
      const response = await changePassword(body, access_token).finally(() =>
        setisLoading(false)
      );
      toast.success("Senha alterada com sucesso");
      setValue("password", "");
      setValue("password_atual", "");
      setValue("password_confirmation", "");
      return response;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.data) {
        const errorChangePassword = err.response.data as ErrorChangePassword;
        toast.error(errorChangePassword.message);
        if (errorChangePassword) {
          Object.entries(errorChangePassword.errors).forEach(([key, value]) => {
            toast.error(value);
          });
        }
      }
    }
  };

  return (
    <Container>
      <LogoLink />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <TextField
            label="Nome"
            variant="standard"
            type="text"
            value={name}
            disabled
          />
        </FormRow>

        <FormRow>
          <TextField
            label="E-mail"
            variant="standard"
            type="email"
            value={email}
            disabled
          />
        </FormRow>

        <FormRow>
          <TextField
            label="Senha atual"
            variant="standard"
            type="password"
            {...register("password_atual")}
            autoComplete="off"
          />
          <p>{errors.password_atual?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="Nova senha"
            variant="standard"
            type="password"
            {...register("password")}
            autoComplete="off"
          />
          <p>{errors.password?.message}</p>
        </FormRow>

        <FormRow>
          <TextField
            label="Confirmar nova senha"
            variant="standard"
            type="password"
            {...register("password_confirmation")}
            autoComplete="off"
          />
          <p>{errors.password_confirmation?.message}</p>
        </FormRow>
        <ActionButton type="submit">
          {isLoading ? <Loading /> : "Atualizar senha"}
        </ActionButton>
        <LinkButton to="/feed"> Voltar para o feed </LinkButton>
      </Form>
    </Container>
  );
};
