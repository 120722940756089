import { useTheme } from "styled-components";

import parseISO from "date-fns/parseISO";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import ptBR from "date-fns/locale/pt-BR";

import { toast } from "react-toastify";

import {
  faFacebook,
  faYoutube,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import {
  faUser,
  faEye,
  faGlobe,
  faThumbsUp,
  faCommentAlt,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as YoutubeLike } from "../../assets/images/icons/youtube-like.svg";
import { ReactComponent as YoutubeShare } from "../../assets/images/icons/youtube-share.svg";

import { ReactComponent as InstagramLike } from "../../assets/images/icons/instagram-like.svg";
import { ReactComponent as InstagramComment } from "../../assets/images/icons/instagram-comment.svg";
import { ReactComponent as TwitterLikes } from "../../assets/images/icons/twitter-likes.svg";
import { ReactComponent as TwitterRetweets } from "../../assets/images/icons/twitter-retwitter.svg";

import { ReactComponent as IcStar } from "../../assets/images/icons/ic-star.svg";
import { ReactComponent as IcStarFilled } from "../../assets/images/icons/ic-star-filled.svg";

import {
  AuthorName,
  TopCard,
  Container,
  ContentText,
  Rates,
  RateItem,
  Thumb,
  Text,
  Tooltip,
  TooltipFav,
  TopCardImage,
  TopCardActions,
  FavoriteButton,
} from "./styles";
import { Mention } from "@/store/ducks/mentions/types";
import { useState } from "react";
import { addFavorite, deleteFavorite } from "@/helper/api";
import { useSelector } from "react-redux";
import { State } from "@/store";
interface CradProps {
  mention: Mention;
}
export const Card = ({ mention }: CradProps) => {
  const theme = useTheme();
  const { data } = useSelector(({ user }: State) => user);
  const { access_token } = data;
  const [isFavorited, setIsFavorited] = useState(() => mention.is_favorite);

  async function handleFavoriteClick(id: string) {
    if (!isFavorited) {
      const addResponse = await addFavorite(id, access_token);

      if (addResponse?.status === 401) {
        toast.error("Não foi possível adicionar aos Favoritos");
        return;
      }

      setIsFavorited(true);
      return;
    }

    setIsFavorited(false);
    deleteFavorite(id, access_token);
  }

  const socials = {
    facebook: {
      color: theme.colors["facebook"],
      icon: faFacebook,
    },
    youtube: {
      color: theme.colors["youtube"],
      icon: faYoutube,
    },
    twitter: {
      color: theme.colors["twitter"],
      icon: faTwitter,
    },
    instagram: {
      color: theme.colors["instagram"],
      icon: faInstagram,
    },
  };

  function mediaClick() {
    let { title, link } = mention;

    const width = 700;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      link,
      title,
      `toolbar=no, location=no, directories=no, status=no,
        menubar=no, resizable=yes, copyhistory=no,
        width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  function getDate() {
    const date = parseISO(mention.created_at);
    const distance = formatDistanceStrict(date, Date.now(), { locale: ptBR });

    return `Há ${distance}`;
  }

  return (
    <Container className={`post ${isFavorited ? "favorited" : ""}`}>
      <TopCard>
        <TopCardImage onClick={() => mediaClick()}>
          {mention.author.picture_url ? (
            <img src={mention.author.picture_url} alt="" />
          ) : (
            <FontAwesomeIcon className="avatar" size="1x" icon={faUser} />
          )}
        </TopCardImage>
        <TopCardActions>
          <div onClick={() => mediaClick()}>
            <AuthorName title={mention.author.name}>
              {mention.author.name ? mention.author.name : mention.title}
            </AuthorName>
            <div className="date">
              <FontAwesomeIcon
                size="1x"
                className="social-brand"
                color={!mention.source ? "#ccc" : socials[mention.source].color}
                icon={!mention.source ? faGlobe : socials[mention.source].icon}
              />
              {mention.created_at && <span>{getDate()}</span>}
            </div>
          </div>
          <div>
            <FavoriteButton
              className="btn-favorite"
              aria-label="Favoritar"
              onClick={() => handleFavoriteClick(mention._id)}
            >
              {isFavorited ? (
                <IcStarFilled width={20} height={20} />
              ) : (
                <IcStar width={20} height={20} />
              )}
              {isFavorited ? (
                <TooltipFav className="tooltip">Remover dos Favoritos</TooltipFav>
              ) : (
                <TooltipFav className="tooltip">Adicionar aos Favoritos</TooltipFav>
              )}
            </FavoriteButton>
          </div>
          
        </TopCardActions>
      </TopCard>
      <Thumb
        hasThumbnail={mention?.attachment && mention?.attachment?.image_url}
        is_video={mention.is_video}
        onClick={() => mediaClick()}
      >
        {mention.is_video && (
          <FontAwesomeIcon
            size="3x"
            className="play"
            color={"#ccc"}
            icon={faPlay}
          />
        )}

        {mention?.attachment ? (
          <img
            src={mention?.attachment.image_url}
            alt={mention?.attachment.title}
          />
        ) : null}
      </Thumb>
      <ContentText onClick={() => mediaClick()}>
        <Text> {mention.message} </Text>
        <Rates>
          {mention.likes ? (
            <RateItem tooltip="Curtidas">
              {mention.source === "facebook" && (
                <FontAwesomeIcon color="#54667a" icon={faThumbsUp} />
              )}

              {mention.source === "youtube" && (
                <YoutubeLike color="#54667a" width={20} height={20} />
              )}

              {mention.source === "instagram" && (
                <InstagramLike color="#54667a" width={20} height={20} />
              )}

              {mention.source === "twitter" && (
                <TwitterLikes color="#54667a" width={20} height={20} />
              )}

              <span>{mention.likes}</span>
              <Tooltip className="tooltip">Curtidas</Tooltip>
            </RateItem>
          ) : null}

          {mention.views ? (
            <RateItem tooltip="Visualizações">
              <FontAwesomeIcon color="#54667a" icon={faEye} />
              <span>{mention.views}</span>
              <Tooltip className="tooltip">Visualizações</Tooltip>
            </RateItem>
          ) : null}

          {mention.shares ? (
            <RateItem tooltip="Curtidas">
              {mention.source === "youtube" && (
                <YoutubeShare color="#54667a" width={20} height={20} />
              )}

              {mention.source === "facebook" && (
                <YoutubeShare color="#54667a" width={20} height={20} />
              )}

              {mention.source === "twitter" && (
                <TwitterRetweets color="#54667a" width={20} height={20} />
              )}

              <span>{mention.shares}</span>
              <Tooltip className="tooltip">
                {mention.source === "twitter"
                  ? "Retweets"
                  : "Compartilhamentos"}
              </Tooltip>
            </RateItem>
          ) : null}
          {mention.comments ? (
            <RateItem tooltip="Curtidas">
              {mention.source === "facebook" && (
                <FontAwesomeIcon color="#54667a" icon={faCommentAlt} />
              )}

              {mention.source === "youtube" && (
                <FontAwesomeIcon color="#54667a" icon={faCommentAlt} />
              )}

              {(mention.source === "instagram" ||
                mention.source === "twitter") && (
                <InstagramComment width={20} height={20} />
              )}

              <span>{mention.comments}</span>
              <Tooltip className="tooltip">Comentários</Tooltip>
            </RateItem>
          ) : null}
        </Rates>
      </ContentText>
    </Container>
  );
};
