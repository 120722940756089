export type OrderItem = {
  id: number;
  value: string;
  title: string;
  active: boolean;
  order: string;
  tooltip?: string;
};

export const orderByList: OrderItem[] = [
  {
    id: 1,
    value: "interactions",
    title: "Envolvimento",
    active: false,
    order: "desc",
    tooltip: "Somatório de todas as reações de um post",
  },
  {
    id: 2,
    value: "engaje",
    title: "Engajamento",
    active: true,
    order: "desc",
    tooltip: "Somatório das reações dividido pelo número de seguidores",
  },
  { id: 3, value: "dateTime", title: "Recente", active: false, order: "desc" },
  {
    id: 4,
    value: "views",
    title: "Visualizações",
    active: false,
    order: "desc",
  },
  {
    id: 5,
    value: "comments",
    title: "Comentários",
    active: false,
    order: "desc",
  },
];
